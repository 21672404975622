import React, { useState, useEffect, useRef } from 'react';

// Use React.forwardRef to pass the ref to the input element
const BodilyPartPicker = React.forwardRef(({
  handleBodilyPartTextChange,
  handleClickBodilyPart,
  bodily_parts,
  onMouseEnter,
  onMouseLeave,
  isConfirmedBodilyPart,
  handleConfirmBodilyPart,
  isConfirmed,
  loadingDoctors,
  handleBackToAge
}, inputRef) => { // inputRef is passed as the ref argument

  const listRef = useRef(null); // Local ref for the list
  const itemHeight = 30;
  const itemCount = bodily_parts.length;
  const [selectedBodilyPart, setSelectedBodilyPart] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (!isConfirmedBodilyPart) {
      setSelectedBodilyPart('');
    }
  }, [isConfirmedBodilyPart]);

  const handleBodilyPartInputChange = (event) => {
    const inputValue = event.target.value;

    setSelectedBodilyPart(event.target.value);
    setSelectedIndex(0);

    const filteredBodilyParts = bodily_parts.filter((body_part) =>
      body_part.name.toLowerCase().startsWith(inputValue.toLowerCase().trim())
    );

    if (filteredBodilyParts.length > 0) {
      handleBodilyPartTextChange(filteredBodilyParts[0].name);
    }
  };

  const handleWheel = (event) => {
    const step = 6;
    setSelectedBodilyPart((prevBodilyPart) =>
      Math.max(
        0,
        Math.min(prevBodilyPart + step * Math.sign(event.deltaY), itemCount - 1)
      )
    );
  };

  const handleBodilyPartSelection = (part, index) => {
    if (!isConfirmedBodilyPart) {
      setSelectedBodilyPart(part);
      setSelectedIndex(index);
      handleClickBodilyPart(part);
    }
  };

  const filteredBodilyParts = bodily_parts.filter((body_part) =>
    body_part.name.toLowerCase().startsWith(selectedBodilyPart.toLowerCase().trim())
  );

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown' && selectedIndex < filteredBodilyParts.length - 1) {
      event.preventDefault();
      setSelectedIndex(selectedIndex + 1);
      handleBodilyPartTextChange(filteredBodilyParts[selectedIndex + 1].name);
    } else if (event.key === 'ArrowUp' && selectedIndex > 0) {
      event.preventDefault();
      setSelectedIndex(selectedIndex - 1);
      handleBodilyPartTextChange(filteredBodilyParts[selectedIndex - 1].name);
    } else if (event.key === 'Enter') {
      event.preventDefault();
      if (selectedIndex >= 0) {
        handleConfirmBodilyPart();
      } else if (filteredBodilyParts.length > 0) {
        handleBodilyPartSelection(filteredBodilyParts[0].name, 0);
        handleConfirmBodilyPart();
      }
    } else if (event.key === 'Backspace' && selectedBodilyPart === '') {
      console.log('BodilyPartBackspace');
      // Go back to age input
      handleBackToAge();
    } else {
      if (filteredBodilyParts[0]) {
        handleBodilyPartTextChange(filteredBodilyParts[0].name);
      }
    }
  };

  useEffect(() => {
    if (listRef.current && selectedIndex !== -1) {
      const selectedElement = listRef.current.children[selectedIndex];
      if (selectedElement) {
        const parentRect = listRef.current.getBoundingClientRect();
        const selectedRect = selectedElement.getBoundingClientRect();

        if (selectedRect.bottom > parentRect.bottom) {
          listRef.current.scrollTop += selectedRect.bottom - parentRect.bottom;
        } else if (selectedRect.top < parentRect.top) {
          listRef.current.scrollTop -= parentRect.top - selectedRect.top;
        }
      }
    }
  }, [selectedIndex]);

  return (
    <div
      style={{
        border: '1px solid #ccc',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column'
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <input
        ref={inputRef} // Forwarded ref is applied here
        type="text"
        value={selectedBodilyPart}
        onChange={handleBodilyPartInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Body part"
        style={{
          padding: '5px',
          fontSize: '14px',
          border: '1px solid #ddd',
          borderRadius: '4px',
          backgroundColor: '#fff',
        }}
        disabled={isConfirmedBodilyPart || !isConfirmed}
        autoFocus={isConfirmed}
      />
      {selectedBodilyPart !== '' && !isConfirmedBodilyPart && (
        <div ref={listRef} style={{ marginTop: '10px', maxHeight: '100px', overflowY: 'auto' }}>
          {filteredBodilyParts.map((body_part, index) => (
            <div
              key={index}
              onClick={() => handleBodilyPartSelection(body_part.name, index)}
              style={{
                padding: '5px',
                cursor: 'pointer',
                backgroundColor: selectedIndex === index ? '#f0f0f0' : 'transparent',
              }}
            >
              <div style={{ fontSize: '1rem' }}>
                {body_part.name}
              </div>
            </div>
          ))}
        </div>
      )}
      {!isConfirmedBodilyPart && !loadingDoctors && selectedBodilyPart !== '' && (
        <button
          style={{
            marginTop: '10px',
            padding: '5px 10px',
            textAlign:'center',
            width: '60%',
            fontSize: '14px',
            background: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            opacity: selectedBodilyPart !== '' ? 1 : 0.5,
          }}
          onClick={handleConfirmBodilyPart}
        >
          Select
        </button>
      )}
    </div>
  );
});

export default BodilyPartPicker;
